import AOS from 'aos';
import Vue from './vue';
import lodash from 'lodash';
import 'aos/dist/aos.css';
import StickySidebar from 'sticky-sidebar';


Object.defineProperty(Vue.prototype, '$_', { value: lodash });

new Vue({
    data() {
        return {
            windowWidth: 0,
            selectedSlide: 0,
            selectedIcon: 0,
            scroll: 0,
            activeSticky: false,
        };
    },
    created() {
        AOS.init();
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        this.$nextTick(() => {
            window.addEventListener('resize', () => {
                this.windowWidth = window.innerWidth;
            });
            this.hs = this.$_.throttle(this.handleScroll, 100);
            window.addEventListener('scroll', this.hs);
        })


        let pricesContent = this.$_.chunk([...this.$refs.prices.querySelectorAll("tr > *")], 1);
        let columns = this.$_.unzip(pricesContent);
        let text = columns.map(this.buildColumn).join("");
        this.$refs.pricesMobile.innerHTML = text;

        new StickySidebar('#sidebar', {
            containerSelector: '#wrapper',
            innerWrapperSelector: '.sidebar__inner',
            topSpacing: 0,
            bottomSpacing: 0
        });
    },

    methods: {
        buildColumn(array) {
            let content = array.map(e => e.innerHTML).join("");
            return `<div class="price-card-mobile">${content}</div>`;
        },
        handleScroll() {
            const { height } = this.$refs.mainHeader.getBoundingClientRect();
            this.activeSticky = (window.scrollY > 20);
        },
        isAndroid() {
            if (/Android/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        isIOS() {
            if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        }
    },

    computed: {
        // mainHeaderHeight() {
        //     return this.windowWidth * (1164 / 1920);
        // }
    },
    el: '#wrapper'
})